import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "react-slideshow-image/dist/styles.css";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { styled } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { axiosInstance } from "../config/axiosInstance";
import swal from "sweetalert";
import loader from "../assets/001 EN.gif";
import axios from "axios";
import { Button } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";

function Auction() {
  const nav = useNavigate();
  const url = useLocation();
  const prodid = url.pathname.split("/");
  const [theme, setTheme] = useState("");
  const [loading, setLoading] = useState(true);
  const [product, setProduct] = useState(null);
  const [openDelete, setOpenDelete] = useState(false);
  const [delet, setDelet] = useState(false);
  const daymode = useSelector((state) => {
    return state.switchMode.mode;
  });
  function handleClose() {
    setOpenDelete(false);
  }
  function confirmDeleteAuction() {
    setOpenDelete(true);
  }
  function deleteAuction() {
    setDelet(true);
    axios.defaults.withCredentials = true;
    axios.defaults.withXSRFToken = true;
    axios
      .get("https://api.2ladue.com/sanctum/csrf-cookie")
      .then((response) => {
        axiosInstance
          .delete(`modules/auction/${prodid[prodid.length - 1]}`)
          .then((res) => {
            swal("Auction deleted successfully");
            setDelet(false);
            nav("/auctions");
          })
          .catch((err) => {
            console.log(err);
            swal("Somthing went wrong");
            setDelet(false);
          });
      })
      .catch((err) => {
        console.log(err);
        setDelet(false);
      });
  }
  useEffect(() => {
    const thememode = localStorage.getItem("theme");
    setTheme(thememode);
  }, [daymode]);
  useEffect(() => {
    axios.defaults.withCredentials = true;
    axios.defaults.withXSRFToken = true;
    axios
      .get("https://api.2ladue.com/sanctum/csrf-cookie")
      .then((result) => {
        axiosInstance
          .get(`/modules/auction/${prodid[prodid.length - 1]}`)
          .then((res) => {
            setProduct(res.data.data);
            setTimeout(() => {
              setLoading(false);
            }, 1000);
          })
          .catch((err) => {
            console.log(err);
            swal("Something went wrong");
          });
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  const EditStyles = styled("div")(({ theme }) => ({
    cursor: "pointer",
    backgroundColor: "#ffe000",
    "&:hover": {
      transform: "scale(1.2)",
    },
  }));
  const DeleteStyles = styled("div")(({ theme }) => ({
    cursor: "pointer",
    backgroundColor: "#ff485e",
    "&:hover": {
      transform: "scale(1.2)",
    },
  }));
  return (
    <>
      {loading ? (
        <div
          className=" d-flex justify-content-center"
          style={{ height: "100vh", backgroundColor: "#fcfcfb" }}
        >
          <img alt="loading" src={loader} className="loading-img" />
        </div>
      ) : (
        <div
          className={`${theme === "Night" ? "bg-dark" : "bg-light"} py-5`}
          style={{ width: "100%", height: "100vh" }}
        >
          <div className="container">
            <div
              className="p-3 rounded-3 mb-3"
              style={{
                boxShadow: "1px 1px 10px 0px lightgrey",
                backgroundColor: theme === "Night" ? "#202442" : "white",
              }}
            >
              <div className="d-flex justify-content-between align-items-center mb-3">
                <div className="fs-3 fw-semibold text-secondary text-capitalize">
                  {product?.title}
                </div>
                <div className="d-flex justify-content-end gap-3">
                  <EditStyles
                    className={`p-2 rounded-2 ${
                      product?.status === "finished" ? "opacity-50" : ""
                    }`}
                    style={{
                      cursor:
                        product?.status === "finished" ? "not-allowed" : "",
                    }}
                    onClick={() => {
                      if (product?.status !== "finished") {
                        nav(`/editauction/${prodid[prodid.length - 1]}`);
                      }
                    }}
                  >
                    <EditOutlinedIcon style={{ color: "white" }} />
                  </EditStyles>
                  <DeleteStyles className="p-2 rounded-2">
                    <DeleteForeverOutlinedIcon
                      style={{ color: "white" }}
                      onClick={confirmDeleteAuction}
                    />
                  </DeleteStyles>
                </div>
              </div>
              <div className="view-page" style={{ fontSize: "0.8rem" }}>
                <div className="img-container px-5 d-flex flex-column justify-content-center">
                  <div
                    className=" d-flex justify-content-center"
                    style={{ height: "100px", overflow: "hidden" }}
                  >
                    <img alt="sli" src={product?.image} />
                  </div>
                </div>
                <div className="w-100">
                  <div className="d-flex justify-content-between py-2">
                    <div className="text-secondary fw-semibold">Code</div>
                    <div
                      style={{ color: theme === "Night" ? "white" : "black" }}
                    >
                      {product?.code}
                    </div>
                  </div>
                  <div className="d-flex justify-content-between py-2">
                    <div className="text-secondary fw-semibold">Price</div>
                    <div
                      className=" text-capitalize"
                      style={{ color: theme === "Night" ? "white" : "black" }}
                    >
                      {product?.price} $
                    </div>
                  </div>
                  <div className="d-flex justify-content-between py-2">
                    <div className="text-secondary fw-semibold">Quantity</div>
                    <div
                      className=" text-capitalize"
                      style={{ color: theme === "Night" ? "white" : "black" }}
                    >
                      {product?.quantity ? product?.quantity : 0}
                    </div>
                  </div>
                  <div className="d-flex justify-content-between py-2">
                    <div className="text-secondary fw-semibold">Status</div>
                    <div
                      className=" text-capitalize"
                      style={{ color: theme === "Night" ? "white" : "black" }}
                    >
                      {product?.status}
                    </div>
                  </div>
                  <div className="d-flex justify-content-between py-2">
                    <div className="text-secondary fw-semibold">Start At</div>
                    <div
                      // className="w-50"
                      style={{ color: theme === "Night" ? "white" : "black" }}
                    >
                      {product?.start ? product?.start : "-"}
                    </div>
                  </div>
                  <div className="d-flex justify-content-between py-2">
                    <div className="text-secondary fw-semibold">End At</div>
                    <div
                      // className="w-50"
                      style={{ color: theme === "Night" ? "white" : "black" }}
                    >
                      {product?.end ? product?.end : "-"}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <Dialog
        open={openDelete}
        keepMounted
        fullWidth
        maxWidth="sm"
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>
          <p>{"Delete Auction"}</p>
          <Divider sx={{ borderColor: "black" }} />
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Are you sure you want to delete this auction?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            variant="contained"
            onClick={deleteAuction}
            disabled={delet}
            color="error"
          >
            {delet ? (
              <CircularProgress size={14} sx={{ color: "white" }} />
            ) : (
              "Delete"
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default Auction;
