import React from "react";
import "react-slideshow-image/dist/styles.css";
import { Fade } from "react-slideshow-image";
import KeyboardArrowRightOutlinedIcon from "@mui/icons-material/KeyboardArrowRightOutlined";
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import DeleteForeverOutlinedIcon from "@mui/icons-material/DeleteForeverOutlined";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import { styled } from "@mui/material/styles";
import ViewStreamIcon from "@mui/icons-material/ViewStream";
import prod from "../assets/prod.png";
import categ from '../assets/category.png';

const ViewStyles = styled("div")(({ theme }) => ({
  cursor: "pointer",
  backgroundColor: "#6cbb88",
  "&:hover": {
    transform: "scale(1.2)",
  },
}));
const EditStyles = styled("div")(({ theme }) => ({
  cursor: "pointer",
  backgroundColor: "#ffe000",
  "&:hover": {
    transform: "scale(1.2)",
  },
}));
const DeleteStyles = styled("div")(({ theme }) => ({
  cursor: "pointer",
  backgroundColor: "#ff485e",
  "&:hover": {
    transform: "scale(1.2)",
  },
}));
const Title = styled("div")(({ theme }) => ({
  transform: "scale(1.25)",
  fontWeight: "600",
  // "&:hover": {
  //   color: "blue",
  //   cursor: "pointer",
  // }
}));

const prodsCol = (productClick, editProduct, deleteProduct) => {
  return [
    {
      name: "ID",
      selector: (row) => row?.id,
      cell: (row) => <div className="py-4">{row?.id}</div>,
      sortable: true,
      // width: "100px",
    },
    {
      name: "Title",
      selector: (row) => row?.title,
      cell: (row) => (
        <Title className="py-4">{row?.title ? row?.title : "-"}</Title>
      ),
      sortable: true,
      // width: "120px",
    },
    {
      name: <p className="text-center w-100">Images</p>,
      selector: (row) => row?.images[0],
      cell: (row) => {
        return (
          <>
            {row?.image.length !== 0 ? (
              <div
                className="slide-container"
                style={{ width: "300px", overflow: "hidden" }}
              >
                <Fade
                  duration={3000}
                  transitionDuration={1000}
                  arrows={true}
                  prevArrow={
                    <KeyboardArrowLeftOutlinedIcon
                      style={{ color: "lightgrey" }}
                    />
                  }
                  nextArrow={
                    <KeyboardArrowRightOutlinedIcon
                      style={{ color: "lightgrey" }}
                    />
                  }
                >
                  {row?.image?.map((item, idx) => {
                    return (
                      <div
                        key={idx}
                        className="d-flex justify-content-center py-4 "
                        style={{ height: "100%" }}
                      >
                        <img
                          src={item.image}
                          alt={idx}
                          className=" rounded-3"
                          style={{ width: "70%", height: "150px" }}
                        />
                      </div>
                    );
                  })}
                </Fade>
              </div>
            ) : (
              <div style={{ width: "300px", overflow: "hidden" }}>
                <div
                  className="d-flex justify-content-center py-4"
                  style={{ height: "100%" }}
                >
                  <img
                    src={prod}
                    alt={"idx"}
                    className=" rounded-3"
                    style={{ width: "70%", height: "150px" }}
                  />
                </div>
              </div>
            )}
          </>
        );
      },
      width: "300px",
    },
    {
      name: "Price",
      selector: (row) => row?.price,
      cell: (row) => (
        <div className=" fw-semibold">
          {row?.price ? row?.price : "-"}
          {" $"}
        </div>
      ),
      sortable: true,
      // width: "100px",
    },
    {
      name: "Type",
      selector: (row) => row?.type,
      cell: (row) => (
        <div className="py-2 px-3 rounded-5 bg-primary fw-semibold bg-opacity-25 text-primary text-capitalize">
          {row?.type}
        </div>
      ),
    },
    {
      name: <p className="text-center w-100">Actions</p>,
      selector: (row) => row?.id,
      width: "160px",
      cell: (row) => (
        <>
          <div className="d-flex justify-content-center gap-2">
            <ViewStyles
              onClick={() => productClick(row?.id)}
              className="p-2 rounded-2"
            >
              <ViewStreamIcon style={{ color: "white" }} />
            </ViewStyles>
            <EditStyles
              className="p-2 rounded-2"
              onClick={() => editProduct(row?.id)}
            >
              <EditOutlinedIcon style={{ color: "white" }} />
            </EditStyles>
            <DeleteStyles className="p-2 rounded-2"
              onClick={() => deleteProduct(row?.id)}
            >
              <DeleteForeverOutlinedIcon style={{ color: "white" }} />
            </DeleteStyles>
          </div>
        </>
      ),
      // width: "120px",
    },
  ];
};

const customersCol = (customerClick) => {
  return [
    {
      name: "Image",
      selector: (row) => row?.image,
      cell: (row) => (
        <div className="py-4">
          <img alt={row?.firstName} src={row?.image} width={50} height={50} />
        </div>
      ),
      sortable: false,
    },
    {
      name: "Full Name",
      selector: (row) => row?.name,
      cell: (row) => (
        <Title className="py-4 me-4">
          {row?.name}
        </Title>
      ),
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row?.email,
      cell: (row) => <div className="py-4">{row?.email}</div>,
      sortable: true,
    },
    {
      name: "Phone Number",
      selector: (row) => row?.mobile,
      cell: (row) => <div className="py-4">{row?.mobile}</div>,
      sortable: false,
    },
    {
      name: "Verified",
      selector: (row) => row?.verified,
      cell: (row) => <div className={`py-2 px-3 rounded-5 fw-semibold bg-opacity-25 ${row?.verified === true ? "bg-success text-success" : "bg-danger text-danger"} text-capitalize`}>
      {row?.verified.toString()}
      </div>,
      sortable: true,
      // width: "120px",
    },
    {
      name: <p className="text-center w-100">Actions</p>,
      selector: (row) => row?.id,
      cell: (row) => (
        <>
          <div className="d-flex justify-content-center gap-3 w-100">
            <ViewStyles
              onClick={() => customerClick(row?.id)}
              className="p-2 rounded-2"
            >
              <ViewStreamIcon style={{ color: "white" }} />
            </ViewStyles>
            {/* <EditStyles className="p-2 rounded-2">
              <EditOutlinedIcon style={{ color: "white" }} />
            </EditStyles> */}
            {/* <DeleteStyles className="p-2 rounded-2">
              <DeleteForeverOutlinedIcon style={{ color: "white" }} />
            </DeleteStyles> */}
          </div>
        </>
      ),
    },
  ];
};

const subsCol = () => {
  return [
    {
      name: "ID",
      selector: (row) => row?.id,
      cell: (row) => <div className="py-4">{row?.id}</div>,
      sortable: true,
      width: "100px",
    },
    {
      name: "Title",
      selector: (row) => row?.related.title,
      cell: (row) => <Title className="py-4">{row?.related?.title}</Title>,
      sortable: true,
      // width: "120px",
    },
    {
      name: "Price",
      selector: (row) => row?.price,
      cell: (row) => (
        <div className=" fw-semibold">
          {Number(row?.price).toFixed(2)}
          {" $"}
        </div>
      ),
      sortable: true,
      // width: "100px",
    },
    {
      name: "Order Status",
      selector: (row) => row?.order_status,
      cell: (row) => (
        <div className=" fw-semibold text-capitalize">
          {row?.order_status}
        </div>
      ),
      sortable: false,
    },
    {
      name: "Payment Status",
      selector: (row) => row?.payment_status,
      cell: (row) => (
        <div className=" fw-semibold text-capitalize">
          {row?.payment_status}
        </div>
      ),
      sortable: false,
    },
    {
      name: "Type",
      selector: (row) => row?.type,
      cell: (row) => (
        <div className="py-2 px-3 rounded-5 bg-warning fw-semibold bg-opacity-25 text-warning text-capitalize">
          {row?.type}
        </div>
      ),
      sortable: false
    },
    {
      name: "Auctions",
      selector: (row) => row?.auction,
      cell: (row) => (
        <div className=" fw-semibold text-capitalize">
          {row?.auction ? row?.auction : "-"}
        </div>
      ),
      sortable: false,
    },
  ];
};

const categCol = (clickCateg, editCateg, deleteCateg) => {
  return [
    {
      name: "ID",
      selector: (row) => row?.id,
      cell: (row) => <div className="py-4">{row?.id}</div>,
      sortable: true,
      width: "100px",
    },
    {
      name: "Icon",
      selector: (row) => row?.icon,
      cell: (row) => (
        <div className="py-4">
          <img alt={row?.firstName} src={row?.icon ? row?.icon : categ} width={50} height={50} />
        </div>
      ),
      sortable: false,
    },
    {
      name: "Title",
      selector: (row) => row?.title,
      cell: (row) => <Title className="py-4">{row?.title ? row?.title : "-"}</Title>,
      sortable: true,
      // width: "120px",
    },
    {
      name: "Description",
      selector: (row) => row?.description,
      cell: (row) => <div>{row?.description ? row?.description : "-"}</div>,
      sortable: true,
      // width: "120px",
    },
    // {
    //   name: "Remaining",
    //   selector: (row) => row?.remaining,
    //   cell: (row) => <div>{row?.remaining}</div>,
    //   sortable: true,
    //   // width: "120px",
    // },
    {
      name: <p className="text-center w-100">Actions</p>,
      selector: (row) => row?.id,
      width: "160px",
      cell: (row) => (
        <>
          <div className="d-flex justify-content-center gap-2 w-100">
          <ViewStyles
              onClick={() => clickCateg(row?.id)}
              className="p-2 rounded-2"
            >
              <ViewStreamIcon style={{ color: "white" }} />
            </ViewStyles>
            <EditStyles className="p-2 rounded-2"
              onClick={()=>editCateg(row?.id)}
            >
              <EditOutlinedIcon style={{ color: "white" }} />
            </EditStyles>
            <DeleteStyles className="p-2 rounded-2"
              onClick={()=>deleteCateg(row?.id)}
            >
              <DeleteForeverOutlinedIcon style={{ color: "white" }} />
            </DeleteStyles>
          </div>
        </>
      ),
    },
  ];
};

const sliderCol = (sliderClick, editSlider, deleteSlider) => {
  return [
    {
      name: "ID",
      selector: (row) => row?.id,
      cell: (row) => <div className="py-4">{row?.id}</div>,
      sortable: true,
      width: "100px",
    },
    {
      name: "Image",
      selector: (row) => row?.image,
      cell: (row) => (
        <div className="py-4">
          <img alt={row?.firstName} src={row?.image} width={50} height={50} />
        </div>
      ),
      sortable: false,
    },
    {
      name: "Title",
      selector: (row) => row?.title,
      cell: (row) => <Title className="py-4 text-capitalize">{row?.title}</Title>,
      sortable: true,
      // width: "120px",
    },
    {
      name: "Type",
      selector: (row) => row?.type,
      cell: (row) => <div className={`py-2 px-3 rounded-5 fw-semibold bg-opacity-25 bg-success text-success text-capitalize`}>{row?.type}</div>,
      sortable: true,
      // width: "120px",
    },
    {
      name: <p className="text-center w-100">Actions</p>,
      selector: (row) => row?.id,
      width: "160px",
      cell: (row) => (
        <>
          <div className="d-flex justify-content-center w-100 gap-2">
            <ViewStyles
              onClick={() => sliderClick(row?.id)}
              className="p-2 rounded-2"
            >
              <ViewStreamIcon style={{ color: "white" }} />
            </ViewStyles>
            <EditStyles className="p-2 rounded-2"
              onClick={()=>editSlider(row?.id)}
            >
              <EditOutlinedIcon style={{ color: "white" }} />
            </EditStyles>
            <DeleteStyles className="p-2 rounded-2"
              onClick={()=>deleteSlider(row?.id)}
            >
              <DeleteForeverOutlinedIcon style={{ color: "white" }} />
            </DeleteStyles>
          </div>
        </>
      ),
    },
  ];
};

const auctionsCol = (auctionClick, editSlider, deleteSlider) => {
  return [
    {
      name: "ID",
      selector: (row) => row?.id,
      cell: (row) => <div className="py-4">{row?.id}</div>,
      sortable: true,
      width: "100px",
    },
    {
      name: "Image",
      selector: (row) => row?.image,
      cell: (row) => (
        <div className="py-4">
          <img alt={row?.firstName} src={row?.image} width={50} height={50} />
        </div>
      ),
      sortable: false,
    },
    {
      name: "Title",
      selector: (row) => row?.title,
      cell: (row) => <Title className="py-4 text-capitalize">{row?.title}</Title>,
      sortable: true,
      // width: "120px",
    },
    {
      name: "Price",
      selector: (row) => row?.price,
      cell: (row) => <Title className="py-4">{row?.price} $</Title>,
      sortable: true,
      // width: "120px",
    },
    {
      name: "Status",
      selector: (row) => row?.status,
      cell: (row) => <div className={`py-2 px-3 rounded-5 fw-semibold bg-opacity-25 ${row?.status === "live" ? "bg-success text-success" : row?.status === "finished" ? "bg-danger text-danger" : "bg-warning text-warning"} text-capitalize`}>
      {row?.status}
      </div>,
      sortable: true,
      // width: "120px",
    },
    {
      name: <p className="text-center w-100">Actions</p>,
      selector: (row) => row?.id,
      width: "160px",
      cell: (row) => (
        <>
          <div className="d-flex justify-content-center w-100 gap-2">
            <ViewStyles
              onClick={() => auctionClick(row?.id)}
              className="p-2 rounded-2"
            >
              <ViewStreamIcon style={{ color: "white" }} />
            </ViewStyles>
            <EditStyles className={`p-2 rounded-2 ${row?.status === "finished" ? "opacity-50" : ""}`} style={{
              cursor: `${row?.status === "finished" ? "not-allowed" : "pointer"}`
            }}
              onClick={()=>{
                if(row?.status !== "finished"){
                  editSlider(row?.id)
                }
              }}
            >
              <EditOutlinedIcon style={{ color: "white" }} />
            </EditStyles>
            <DeleteStyles className="p-2 rounded-2"
              onClick={()=>deleteSlider(row?.id)}
            >
              <DeleteForeverOutlinedIcon style={{ color: "white" }} />
            </DeleteStyles>
          </div>
        </>
      ),
    },
  ]
}

const packageCol = (packageClick, editPackage, deletePackage) => {
  return [
    {
      name: "ID",
      selector: (row) => row?.id,
      cell: (row) => <div className="py-4">{row?.id}</div>,
      sortable: true,
      width: "100px",
    },
    {
      name: "Title",
      selector: (row) => row?.title,
      cell: (row) => <Title className="py-4 text-capitalize">{row?.title}</Title>,
      sortable: true,
      // width: "120px",
    },
    {
      name: "Bid Value",
      selector: (row) => row?.bid_value,
      cell: (row) => <Title className="py-4">{row?.bid_value} $</Title>,
      sortable: true,
      // width: "120px",
    },
    {
      name: "Total Bids",
      selector: (row) => row?.total_bids,
      cell: (row) => <div className="py-4">{row?.total_bids}</div>,
      sortable: true,
      // width: "100px",
    },
    {
      name: "Period",
      selector: (row) => row?.duration_str,
      cell: (row) => <div>{row?.duration_str}</div>,
      sortable: true,
      // width: "120px",
    },
    {
      name: <p className="text-center w-100">Actions</p>,
      selector: (row) => row?.id,
      width: "160px",
      cell: (row) => (
        <>
          <div className="d-flex justify-content-center w-100 gap-2">
            <ViewStyles
              onClick={() => packageClick(row?.id)}
              className="p-2 rounded-2"
            >
              <ViewStreamIcon style={{ color: "white" }} />
            </ViewStyles>
            <EditStyles className="p-2 rounded-2"
              onClick={()=>editPackage(row?.id)}
            >
              <EditOutlinedIcon style={{ color: "white" }} />
            </EditStyles>
            <DeleteStyles className="p-2 rounded-2"
              onClick={()=>deletePackage(row?.id)}
            >
              <DeleteForeverOutlinedIcon style={{ color: "white" }} />
            </DeleteStyles>
          </div>
        </>
      ),
    },
  ]
}

const settingsCol = (settingsClick, editSettings) => {
  return [
    {
      name: "ID",
      selector: (row) => row?.id,
      cell: (row) => <div className="py-4">{row?.id}</div>,
      sortable: true,
      width: "100px",
    },
    {
      name: "Title",
      selector: (row) => row?.title,
      cell: (row) => <Title className="py-4 text-capitalize">{row?.title}</Title>,
      sortable: true,
      // width: "120px",
    },
    {
      name: "Type",
      selector: (row) => row?.type,
      cell: (row) => <div className={`py-2 px-3 rounded-5 fw-semibold bg-opacity-25 bg-primary text-primary text-capitalize`}>{row?.type}</div>,
      sortable: true,
      // width: "120px",
    },
    {
      name: <p className="text-center w-100">Actions</p>,
      selector: (row) => row?.id,
      cell: (row) => (
        <>
          <div className="d-flex justify-content-center w-100 gap-3">
            <ViewStyles
              onClick={() => settingsClick(row?.id)}
              className="p-2 rounded-2"
            >
              <ViewStreamIcon style={{ color: "white" }} />
            </ViewStyles>
            <EditStyles className="p-2 rounded-2"
              onClick={()=>editSettings(row?.id)}
            >
              <EditOutlinedIcon style={{ color: "white" }} />
            </EditStyles>
          </div>
        </>
      ),
    },
  ]
}

const prodsColDash = () => {
  return [
    {
      name: "ID",
      selector: (row) => row?.id,
      cell: (row) => <div className="py-4">{row?.id}</div>,
      sortable: true,
      // width: "100px",
    },
    {
      name: "Title",
      selector: (row) => row?.title,
      cell: (row) => (
        <Title className="py-4">{row?.title ? row?.title : "-"}</Title>
      ),
      sortable: true,
      // width: "120px",
    },
    {
      name: "Price",
      selector: (row) => row?.price,
      cell: (row) => (
        <div className=" fw-semibold">
          {row?.price ? row?.price : "-"}
          {" $"}
        </div>
      ),
      sortable: true,
      // width: "100px",
    },
  ];
};

const customersColDash = () => {
  return [
    {
      name: "Full Name",
      selector: (row) => row?.name,
      cell: (row) => (
        <Title className="py-4 me-4">
          {row?.name}
        </Title>
      ),
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row?.email,
      cell: (row) => <div className="py-4">{row?.email}</div>,
      sortable: true,
    },
    {
      name: "Phone Number",
      selector: (row) => row?.mobile,
      cell: (row) => <div className="py-4">{row?.mobile}</div>,
      sortable: false,
    },
  ];
};

const categColDash = () => {
  return [
    {
      name: "ID",
      selector: (row) => row?.id,
      cell: (row) => <div className="py-4">{row?.id}</div>,
      sortable: true,
      // width: "100px",
    },
    {
      name: "Title",
      selector: (row) => row?.title,
      cell: (row) => <Title className="py-4">{row?.title ? row?.title : "-"}</Title>,
      sortable: true,
      // width: "120px",
    },
  ];
};

const sliderColDash = () => {
  return [
    {
      name: "ID",
      selector: (row) => row?.id,
      cell: (row) => <div className="py-4">{row?.id}</div>,
      sortable: true,
      width: "100px",
    },
    {
      name: "Title",
      selector: (row) => row?.title,
      cell: (row) => <Title className="py-4 text-capitalize">{row?.title}</Title>,
      sortable: true,
      // width: "120px",
    },
    {
      name: "Type",
      selector: (row) => row?.type,
      cell: (row) => <div className={`py-2 px-3 rounded-5 fw-semibold bg-opacity-25 bg-success text-success text-capitalize`}>{row?.type}</div>,
      sortable: true,
      // width: "120px",
    },
  ];
};

const auctionsColDash = () => {
  return [
    {
      name: "ID",
      selector: (row) => row?.id,
      cell: (row) => <div className="py-4">{row?.id}</div>,
      sortable: true,
      width: "100px",
    },
    {
      name: "Title",
      selector: (row) => row?.title,
      cell: (row) => <Title className="py-4 text-capitalize">{row?.title}</Title>,
      sortable: true,
      // width: "120px",
    },
    {
      name: "Status",
      selector: (row) => row?.status,
      cell: (row) => <div className={`py-2 px-3 rounded-5 fw-semibold bg-opacity-25 ${row?.status === "live" ? "bg-success text-success" : row?.status === "finished" ? "bg-danger text-danger" : "bg-warning text-warning"} text-capitalize`}>
      {row?.status}
      </div>,
      sortable: true,
      // width: "120px",
    },
  ]
}

const packageColDash = () => {
  return [
    {
      name: "ID",
      selector: (row) => row?.id,
      cell: (row) => <div className="py-4">{row?.id}</div>,
      sortable: true,
      width: "100px",
    },
    {
      name: "Title",
      selector: (row) => row?.title,
      cell: (row) => <Title className="py-4 text-capitalize">{row?.title}</Title>,
      sortable: true,
      // width: "120px",
    },
    {
      name: "Bid Value",
      selector: (row) => row?.bid_value,
      cell: (row) => <Title className="py-4">{row?.bid_value} $</Title>,
      sortable: true,
      // width: "120px",
    },
    {
      name: "Total Bids",
      selector: (row) => row?.total_bids,
      cell: (row) => <div className="py-4">{row?.total_bids}</div>,
      sortable: true,
      // width: "100px",
    },
  ]
}

export { prodsCol, customersCol, subsCol, categCol, sliderCol, auctionsCol, packageCol, settingsCol, prodsColDash, customersColDash, categColDash, sliderColDash, auctionsColDash, packageColDash };